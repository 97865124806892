<template>
  <v-card class="mt-4 mx-5 pa-5 pb-0 ">
    <v-card
      class="pa-3  mb-6"
      tile
      outlined
      max-width="500"
    >
      <div class="primary--text"  v-if="this.$route.name === 'EtabStatistique'">
        <p class="secondary--text font-weight-bold">{{ InfoEtab.id }}</p>
        <p>{{ InfoEtab.nom }}</p>
        <p class="mb-0">{{ InfoEtab.cp }} {{ InfoEtab.ville }}</p>
      </div>
      <div v-if="this.$route.name === 'UserStatistique'&&user">
     
        <p>{{ user.prenom }} {{ user.nom }} </p>
        <p>Identifiant : <span class="secondary--text">{{ user.identifiant }}</span></p>
        <p>Email : {{ user.emailContact }}</p>
        <p v-if="user.etablissements">Etablissement : {{user.etablissements.nom}} {{ user.etablissements.cp}} {{ user.etablissements.ville}}</p>
        <p class="mb-0">Rôle : {{ user.role }}</p>

      </div>
    </v-card >
    <NextButton
      width="100%"
      color="green"
      text="voir l'etablissement"
      @clicked="$router.push({ path: `/admin/etablissement/statistique/${user.etablissements.id}` }).catch((error) => {
        if (error.name !== 'NavigationDuplicated') {
    throw error;
    }
    })"
      class="mb-5 mr-5"
       v-if="
        this.$route.name === 'UserStatistique'
      "
    />
    <NextButton
      width="100%"
      color="green"
      text="voir les utilisateurs"
      @clicked="$router.push({ path: `/admin/users?search=${$route.params.id}` }).catch((error) => {
        if (error.name !== 'NavigationDuplicated') {
    throw error;
    }
    })"
      class="mb-5"
       v-if="
        this.$route.name === 'EtabStatistique'
      "
    />
  </v-card>
</template>
<script>
import NextButton from "./../Utility/NextButton.vue";
export default {
     props: ["InfoEtab","user","datemin","datemax"],
  components: {
    NextButton,
  },
  computed: {},
  data: () => ({}),

  methods: {},
};
</script>
<style lang="scss" scoped>
</style>
